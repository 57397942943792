import configDev from './config-dev';
import configProd from './config-prod';
import configStaging from './config-stage';

export const PLATFORMS = {
  STREAMING: 'streaming',
  CHAT: 'chat',
};

let config = {
  platform: process.env.REACT_APP_PLATFORM,
  PLATFORMS,
  isChat: process.env.REACT_APP_PLATFORM === PLATFORMS.CHAT,
  isStream: process.env.REACT_APP_PLATFORM === PLATFORMS.STREAMING,
};

if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  config = {...configProd, ...config};
} else if (process.env.REACT_APP_ENV === 'STAGING') {
  config = {...configStaging, ...config};
} else {
  config = {...configDev, ...config};
}

export default config;
